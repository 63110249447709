<template>
    <div class="login-box">
        <div class="login-wrap">
            <div class="content">
                <div class="main">
                    <div class="main-left">
                        <el-carousel indicator-position="outside" height="349px" arrow="never">
                            <el-carousel-item v-for="(item,index) in bannerList" :key="index">
                            <img :src="item.img" alt="">
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="main-right">
                        <div class="main-right-logo">
                            <img src="../assets/img/loginIcon/logo.png" alt="">
                        </div>
                        <div class="login-header-title">
                            <a :class="{active:loginWay}" href="javascript:;" rel="external nofollow" @click="loginWay=true">账号登录</a>
                            <a :class="{active:!loginWay}" href="javascript:;" rel="external nofollow" @click="loginWay=false">手机验证登录</a>
                        </div>
                        <div :class="{on:!loginWay}">
                            <div class="input">
                                <div class="input-icon">
                                    <img src="../assets/img/loginIcon/menber-icon.png" alt="">
                                </div>
                                <input type="text" placeholder="请输入您的账号" v-model="username">
                            </div>
                            <div class="input">
                                <div class="input-icon">
                                    <img src="../assets/img/loginIcon/password-icon.png" alt="">
                                </div>
                                <form>
                                <input :type="typePwd" placeholder="请输入您的密码" v-model="password" autocomplete="off">
                                </form>
                                <div class="forgetIcon">
                                    <img :src="seen?openeye:closeeye" @click="showPwd">
                                </div>
                            </div>
                            <div class="forgetBox">
                                <a href="javascript:;" class="forget" @click="forget()">忘记密码</a>
                            </div>
                        </div>
                        <div :class="{on:loginWay}">
                            <div class="input">
                                <div class="phone-icon">
                                    <img src="@/assets/img/loginIcon/phone-icon.png" alt="">
                                </div>
                                <input type="text" placeholder="请输入手机号码" v-model="phone">
                            </div>
                            <div class="code">
                                <div class="code-icon">
                                    <img src="../assets/img/loginIcon/code-icon.png" alt="">
                                </div>
                                <input type="text" maxlength="4" placeholder="请输入验证码" v-model="imgCode">
                                <img :src="codeImg" alt="" @click="getCaptcha">
                                <!-- <img src="http://192.168.1.138:8090/api/v2/yun/login/get_verify_code.html" @click="getCaptcha" id="img"/> -->
                            </div>
                            <div class="input">
                                <div class="message-icon">
                                    <img src="../assets/img/loginIcon/message-icon.png" alt="">
                                </div>
                                <input type="text" placeholder="请输入短信验证码" v-model="code">
                                <button :disabled="!rightPhone" class="getCode" :class="{right_phone: rightPhone}" @click.prevent="getCode">{{computerTime>0 ? `已发送(${computerTime}s)` : '获取验证码'}}</button>
                            </div>
                        </div>

                        <div class="login-button">
                            <button @click="login">登录</button>
                        </div>
                        <div class="login-register">
                            <span>没有账号吗？<a href="/#/Register" class="register">快速注册>></a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { mapActions } from 'vuex';
export default {
    name: 'login',
    inject:['reload'],
    data() {
        return {
            bannerList: [
                {
                    img: require('../../public/img/index/live.png')
                },
                {
                    img: require('../../public/img/index/bank.png')
                },
                {
                    img: require('../../public/img/index/video.png')
                }
            ],
            username:'', // 用户名
            phone: '', // 手机号码
            password:'', // 密码
            code: '', // 验证码
            codeImg: 'https://qd.gzzdwx.com/api/v2/yun/login/get_verify_code.html',
            imgCode: '', // 图片验证码
            loginWay:true,
            typePwd:'password',
            computerTime: 0,
            user: '',
            seen: '', // 图片显示
            openeye: require('../assets/img/loginIcon/password-show.png'),
            closeeye: require('../assets/img/loginIcon/password-hide.png')

        }
    },
    metaInfo: {
        title: '登录-网校', // set a title
        meta: [
            {             // set meta
                name: 'keyWords',
                content: '我是contact关键字'
            },
            {
                name: 'description',
                content: '我是contact描述'
            }
        ]
    },
    computed: {
        rightPhone() {
            return /^1\d{10}$/.test(this.phone)
        }
    },
    methods: {
        // 获取一个新的图片验证码
        showPwd (){
            this.typePwd = this.typePwd === 'password' ? 'text' : 'password';
            this.seen = !this.seen;
        },
        getCaptcha () {
            // document.getElementById("img").src="http://192.168.1.138:8090/api/v2/yun/login/get_verify_code.html?"+Math.random();
            this.codeImg = 'https://qd.gzzdwx.com/api/v2/yun/login/get_verify_code.html?'+Math.random();
            // console.log(window.location.host)
        },
        getCode() {
            if(!this.computerTime) {
                // 启动倒计时
                this.computerTime = 60
                this.intervalID = setInterval(() => {
                    this.computerTime--
                    if(this.computerTime<=0){
                        clearInterval(this.intervalID)
                    }
                }, 1000)
                this.axios({
                    method: 'POST',
                    url: 'api/v2/yun/login/get_sms_code.html',
                    data: {
                        phoneNumber: this.phone,
                    }
                }).then((res) => {
                    let error = res.data.code;
                    if(error == 2104) {
                        alert("短信发送失败")
                    }
                })
            }
            
        },
        login(){
            if(!this.loginWay) { // 短信登录
                const {phone, code} = this; // eslint-disable-line no-unused-vars
                if(!this.rightPhone) {
                    // 手机号不正确
                    alert("手机号不正确");
                    return
                } else if(!/^\d{6}$/.test(code)) {
                    // 验证必须是6位数字
                    alert("验证必须是6位数字");
                    return
                }
                
                this.axios({
                    method: 'POST',
                    url: 'api/v2/yun/login/login_by_phone.html',
                    data: {
                        phoneNumber: this.phone,
                        verifyCode: this.imgCode,
                        smsCode: this.code
                    }
                }).then((res) => {
                    // console.log(res);
                    var that = this;
                    that.user = res.data.result.name;
                    localStorage.setItem('token',res.data.result.token)
                    that.$store.commit('$_setStorage', that.user)
                    this.reload();
                    setTimeout(function(){
                        that.$router.push(
                            {
                                name:'index',
                                params:{
                                    from:'login'
                                }
                            }
                        )
                    },1000)
                })
                return;
            } else { // 密码登录
                const {username, password} = this; // eslint-disable-line no-unused-vars
                if(!this.username) {
                    // 用户必须指定
                    alert("用户名必须填写");
                    return
                } else if(!this.password) {
                    // 密码必须指定
                    alert("密码必须填写");
                    return
                } 
                this.axios({
                    method: 'POST',
                    url: 'api/v2/yun/login/login_by_password.html',
                    data: {
                        account: this.username,
                        password: this.password
                    }
                }).then((res) => {
                    var that = this;
                    if(res.data.code == 2107) {
                        alert("账号不存在")
                    } else if(res.data.code == 2109) {
                        alert("密码不正确")
                    }else {
                        that.user = res.data.result.name;
                        localStorage.setItem('token',res.data.result.token)
                        localStorage.setItem('userId',res.data.result.id)
                        that.$store.commit('$_setStorage', this.user)
                        this.reload();
                        setTimeout(function(){
                            that.$router.push(
                                {
                                    name:'index',
                                    params:{
                                        from:'login'
                                    }
                                }
                            )
                        },1000)
                    }
                })
            }
            
        },
        forget() {
            this.$router.push('/forget');
        }
    }
}
</script>
<style lang="scss" scoped>
    .login-box {
        .login-wrap {
            padding-top: 92px;
            width: 100%;
            min-width: 1200px;
            height: 672px;
            background: #e9eaed;
            .content {
                padding-top: 48px;
                margin: 0 auto;
                width: 1200px;
                height: 592px;
                // background: #FFFFFF;
                .main {
                    margin: 0 auto;
                    width: 913px;
                    display: flex;
                    justify-content: space-between;
                    .main-left {
                        margin-top: 59px;
                        width: 366px;
                        height: 444px;
                        .el-carousel--horizontal {
                            & /deep/ .el-carousel__container {
                                margin-bottom: 40px;
                            }
                            // & /deep/ .el-carousel__indicators {
                            //     & /deep/ .el-carousel__indicator--horizontal {
                                    
                            //     }
                            // }
                        }
                    }
                    
                    .main-right {
                        position: relative;
                        padding-top: 30px;
                        padding-left: 40px;
                        padding-right: 40px;
                        box-sizing: border-box;
                        width: 380px;
                        height: 525px;
                        background: #FFFFFF;
                        border-radius: 6px;
                        .forgetBox {
                            margin-bottom: 30px;
                            text-align: right;
                            .forget {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                            }
                        }
                        .main-right-logo {
                            margin: 0 auto;
                            margin-bottom: 25px;
                            width: 169px;
                            height: 40px;
                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }
                        .login-header-title {
                            margin: 0 auto 40px;
                            width: 213px;
                            display: flex;
                            justify-content: space-between;
                            a {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #999999;
                                padding-bottom: 10px;
                                box-sizing: border-box;
                            }
                            .active {
                                position: relative;
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                border-bottom: 2px solid #FA4B66;
                                // &:before {
                                //     content: '';
                                //     position: absolute;
                                //     bottom: -10px;
                                //     width: 120px;
                                //     height: 2px;
                                //     background: #FA4B66;
                                // }
                            }
                            .line {
                                font-size: 20px;
                                color: #CCCCCC;
                            }
                        }
                        .on {
                            display: none;
                        }
                        .input {
                            margin-bottom: 18px;
                            position: relative;
                            input {
                                width: 300px;
                                height: 50px;
                                border: 1px solid #DEDEDE;
                                padding-left: 37px;
                                box-sizing: border-box;
                            }
                            .input-icon {
                                position: absolute;
                                top: 17px;
                                left: 12px;
                                width: 16px;
                                height: 16px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .phone-icon {
                                position: absolute;
                                top: 17px;
                                left: 12px;
                                width: 14px;
                                height: 19px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .message-icon {
                                position: absolute;
                                top: 18px;
                                left: 12px;
                                width: 15px;
                                height: 15px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            .getCode {
                                position: absolute;
                                top: 1px;
                                right: -2px;
                                width: 100px;
                                height: 50px;
                                border: none;
                                background: #FF5A74;
                                border-radius: 0px 3px 3px 0px;
                                color: #ffffff;
                                cursor: pointer;
                                // &.right_phone {
                                //     // cursor: none;
                                // }
                            }
                            .forgetIcon {
                                position: absolute;
                                top: 19px;
                                right: 10px;
                                width: 21px;
                                height: 14px;
                                cursor: pointer;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                        }
                        .code {
                            margin-bottom: 20px;
                            display: flex;
                            position: relative;
                            .code-icon {
                                position: absolute;
                                top: 19px;
                                left: 12px;
                                width: 15px;
                                height: 13px;
                                img {
                                    width: 100%;
                                    height: 100%;
                                }
                            }
                            input {
                                padding-left: 37px;
                                box-sizing: border-box;
                                width: 200px;
                                height: 50px;
                                border: 1px solid #DEDEDE;
                            }
                            img {
                                width: 100px;
                                height: 50px;
                            }
                        }

                        .login-button {
                            button {
                                margin-bottom: 14px;
                                width: 300px;
                                height: 50px;
                                background: #FA4B66;
                                border-radius: 25px;
                                font-size: 18px;
                                font-family: Microsoft YaHei;
                                color: #FFFFFF;
                                background-color:#FA4B66;
                                border:none;
                                cursor: pointer;
                            }
                        }
                        .login-register {
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 380px;
                            text-align: center;
                            height: 50px;
                            line-height: 50px;
                            background: #F7F7F7;
                            border-radius: 0px 0px 6px 6px;
                            border-top: 1px solid #EEEEEE;
                            span {
                                font-size: 14px;
                                font-family: Microsoft YaHei;
                                font-weight: 400;
                                color: #333333;
                                .register {
                                    color: #FA4B66;
                                }
                            }
                            
                        }
                    }
                }
            }
        }
    }
</style>